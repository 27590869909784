* {
  color: #36151E;
}

a, button /* &c... */ {
  all: unset;
  white-space: pre-line;
  word-wrap: break-word
}

#root {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #D8D8F6;
}

.App {
  overflow: hidden; 
  height: 100%;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
}

.home-icon {
  border-bottom: 2px solid #D8D8F6;
  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #B18FCF;
  }
}

.Error {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  H1 {
    font-size: 40px;
  }
}

.home-logo {
  margin: 4px;
  height: 60px;
}

.main-page {
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar { 
      display: none; 
      width: 0 !important /* Safari and Chrome */
  }
  a {
    text-decoration: underline;
    color: #593F62;
    &:hover {
        cursor: pointer;
    }
  }
}